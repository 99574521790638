import NavLinks from "./NavLinks";
import { Link } from "@remix-run/react";
import logo from "../assets/KptnCookLogo.png";
import { getCMSEnvironment } from "../server/utils";
import TestCMSBanner from "../assets/TestCMSBanner.png";

const Sidebar = () => {
  const isProduction = getCMSEnvironment() === "production";

  const classes = `fixed bottom-0 left-0 top-0 z-50 w-[250px] overflow-y-scroll  no-scrollbar ${isProduction ? "bg-menuBgColor" : "bg-[#19334d]"}`;

  return (
    <section>
      <aside className={classes}>
        <div className="mx-auto mb-5 mt-0 grid w-[80%] place-items-center border-b-[0.5px] border-solid border-bgColor py-5">
          <Link to="/">
            <img src={logo} className="h-10" alt="Logo" />
          </Link>
          {!isProduction && (
            <div className="mt-6">
              <img src={TestCMSBanner} alt="TEST CMS" className="h-6" />
            </div>
          )}
        </div>
        <NavLinks />
      </aside>
    </section>
  );
};

export default Sidebar;
