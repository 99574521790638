import _ from "lodash";
import {
  AccountCreateValues,
  AccountEditValues,
  ChangePassWordValues,
  MyAccValues,
  OwnedAccValues,
  RecipeFavValues,
  TransferFavValues,
} from "./types";
import { Revalidator } from "~/types";
import { patchData, postData, postDataExpectId } from "~/server/api_ts.client";
import { createToastMessage } from "~/server/utils";

export const handleUpdateAccount = async (
  formData: AccountEditValues,
  revalidator: Revalidator
) => {
  const { id } = formData;

  const path = `/accounts/${id}`;
  const res = await patchData(path, formData);

  const toastMessage = createToastMessage(
    res,
    `${formData.firstName} ${formData.lastName} Updated`
  );

  if (res.success) {
    revalidator.revalidate();
  }

  return toastMessage;
};

export const handleCreateAccount = async (formData: AccountCreateValues) => {
  const path = `/accounts`;
  const res = await postDataExpectId(path, formData);

  const toastMessage = createToastMessage(
    res,
    `${formData.firstName} ${formData.lastName} Created`
  );

  if (res.failure) {
    return { toastMessage };
  }

  const redirectPath = res.data.id && `/accounts/${res.data.id}`;
  return { toastMessage, redirectPath };
};

export const handleTransferFavs = async (
  formData: TransferFavValues,
  revalidator: Revalidator
) => {
  const path = `/accounts/transferFavorites`;
  const res = await postData(path, formData, 200);

  const toastMessage = createToastMessage(res, `Transfer Complete`);

  if (res.success) {
    revalidator.revalidate();
  }

  return toastMessage;
};

export const handleGrantPromoAccess = async (
  id: string,
  revalidator: Revalidator
) => {
  const path = `/accounts/${id}/grantSubscriptionAccess`;
  const res = await postData(path, {}, 200);
  const toastMessage = createToastMessage(res, "Promo Access Granted");

  if (res.success) {
    revalidator.revalidate();
  }

  return toastMessage;
};

export const handleRevokePromoAccess = async (
  id: string,
  revalidator: Revalidator
) => {
  const path = `/accounts/${id}/revokeSubscriptionAccess`;
  const res = await postData(path, {}, 200);

  const toastMessage = createToastMessage(res, "Promo Access Revoked");

  if (res.success) {
    revalidator.revalidate();
  }

  return toastMessage;
};

export const handleChangePassword = async (
  formData: ChangePassWordValues,
  revalidator: Revalidator
) => {
  const { id, password } = formData;

  const path = `/accounts/${id}/password`;
  const res = await postData(path, { password }, 200);

  const toastMessage = createToastMessage(res, `Password Updated`);

  if (res.success) {
    revalidator.revalidate();
  }

  return toastMessage;
};

export const handleAddRecipe = async (
  formData: RecipeFavValues,
  revalidator: Revalidator,
  id: string
) => {
  const { recipeId } = formData;

  const path = `/accounts/${id}/favorites`;
  const res = await postData(path, { id: recipeId }, 200);

  const toastMessage = createToastMessage(res, `Recipe Added`);

  if (res.success) {
    revalidator.revalidate();
  }

  return toastMessage;
};

export const handleUpdateMyAccount = async (
  formData: MyAccValues,
  revalidator: Revalidator
) => {
  const path = `/accounts/me`;
  const res = await patchData(path, formData);

  const toastMessage = createToastMessage(res, `Recipe Added`);

  if (res.success) {
    revalidator.revalidate();
  }

  return toastMessage;
};

export const handleUpdateOwnedAccount = async (
  formData: OwnedAccValues,
  revalidator: Revalidator,
  id: string
) => {
  const path = `/accounts/owned/${id}`;

  const res = await patchData(path, formData);

  const toastMessage = createToastMessage(
    res,
    `${formData.firstName} ${formData.lastName} Updated`
  );

  if (res.success) {
    revalidator.revalidate();
  }

  return toastMessage;
};

export const handleChangeMyPassword = async (
  formData: ChangePassWordValues,
  revalidator: Revalidator
) => {
  const { password } = formData;

  const path = `/accounts/me/password`;
  const res = await postData(path, { password }, 200);

  const toastMessage = createToastMessage(res, `Password Updated`);

  if (res.success) {
    revalidator.revalidate();
  }

  return toastMessage;
};

export const handleChangeOwnedAccPassword = async (
  formData: ChangePassWordValues,
  revalidator: Revalidator
) => {
  const { id, password } = formData;

  const path = `/accounts/owned/${id}/password`;
  const res = await postData(path, { password }, 200);

  const toastMessage = createToastMessage(res, `Recipe Added`);

  if (res.success) {
    revalidator.revalidate();
  }

  return toastMessage;
};

export const handleAddMyRecipeFav = async (
  formData: RecipeFavValues,
  revalidator: Revalidator
) => {
  const { recipeId } = formData;

  const path = `/accounts/me/favorites`;
  const res = await postData(path, { id: recipeId }, 200);

  const toastMessage = createToastMessage(res, `Recipe Added`);

  if (res.success) {
    revalidator.revalidate();
  }

  return toastMessage;
};

export const handleAddOwnAccRecipe = async (
  formData: RecipeFavValues,
  revalidator: Revalidator,
  id?: string
) => {
  const { recipeId } = formData;

  const path = `/accounts/owned/${id}/favorites`;
  const res = await postData(path, { id: recipeId }, 200);

  const toastMessage = createToastMessage(res, `Recipe Added`);

  if (res.success) {
    revalidator.revalidate();
  }

  return toastMessage;
};
