import { Link } from "@remix-run/react";
import { getTimestamp } from "./utils";
import StatusBadge from "../../components/StatusBadge";
import { convertDateToString } from "~/utils/convertDateToString";
import { MRT_ColumnDef } from "material-react-table";
import { AccountRow, AccRecipeFav, OwnedAccount, SubHistory } from "./types";
import { Check } from "~/components";

export const STATUS_OPTIONS = [
  { value: "active", id: 1 },
  { value: "inactive", id: 2 },
  { value: "register", id: 3 },
];

export const acceptedCmsRoles = ["admin", "customerAgent", "analyst"];
export const canDeleteOrModify = ["admin", "customerAgent"];

export const OWNED_ACC_COLS: Array<MRT_ColumnDef<OwnedAccount>> = [
  {
    header: "First Name",
    accessorKey: "firstName",
    Cell: ({ row: { original } }) => (
      <Link className="titleLink" to={`/accounts/owned/${original.id}`}>
        {original.firstName}
      </Link>
    ),
  },
  {
    header: "Last Name",
    accessorKey: "lastName",
    Cell: ({ row: { original } }) => (
      <Link className="titleLink" to={`/accounts/owned/${original.id}`}>
        {original.lastName}
      </Link>
    ),
  },
  {
    header: "Email",
    accessorKey: "email",
    Cell: ({ row: { original } }) => (
      <Link className="titleLink" to={`/accounts/owned/${original.id}`}>
        {original.email}
      </Link>
    ),
  },
];

export const SUB_HISTORY_COLS: Array<MRT_ColumnDef<SubHistory>> = [
  {
    header: "Start Date",
    accessorKey: "startDate",
    Cell: ({ row: { original } }) => (
      <span>
        {original?.startDate
          ? getTimestamp(new Date(original.startDate))
          : "n/a"}
      </span>
    ),
  },
  {
    header: "End Date",
    accessorKey: "endDate",
    Cell: ({ row: { original } }) => (
      <span>
        {original?.endDate ? getTimestamp(new Date(original.endDate)) : "n/a"}
      </span>
    ),
  },
  {
    header: "Type",
    accessorKey: "subscriptionType",
    Cell: ({ row: { original } }) => (
      <span>
        {original?.subscriptionType ? (
          <StatusBadge status={original?.subscriptionType} />
        ) : (
          "n/a"
        )}
      </span>
    ),
  },
];

export const RECIPE_FAVS_COLS: Array<MRT_ColumnDef<AccRecipeFav>> = [
  {
    header: "gdocs",
    accessorKey: "gdocs",
    size: 5,
    Cell: ({ row: { original } }) => (
      <Link className="titleLink" to={`/recipes/${original.identifier}`}>
        {original.gdocs}
      </Link>
    ),
  },
  {
    header: "Title",
    accessorKey: "title",
    size: 400,
    Cell: ({ row: { original } }) => (
      <Link className="titleLink" to={`/recipes/${original.identifier}`}>
        {original.title}
      </Link>
    ),
  },
  {
    accessorKey: "deleted",
    header: "Deleted by User",
    Cell: ({ row: { original } }) => (original.deleted ? <Check /> : null),
  },
];

export const ADD_ACCOUNT_COLS: Array<MRT_ColumnDef<AccountRow>> = [
  { header: "First Name", accessorKey: "firstName", enableColumnFilter: true },
  { header: "Last Name", accessorKey: "lastName", enableColumnFilter: true },
  {
    header: "Email",
    accessorKey: "email",
    enableColumnFilter: true,
  },
  { header: "CMS Role", accessorKey: "cmsRole", enableColumnFilter: false },
  { header: "", accessorKey: "id", Cell: () => null },
];

export const getAccountsCols = (
  cmsRole?: string | null
): Array<MRT_ColumnDef<AccountRow>> => [
  {
    header: "First Name",
    accessorKey: "firstName",
    Cell: ({ row: { original } }) => {
      return cmsRole && canDeleteOrModify.includes(cmsRole) ? (
        <Link className="titleLink" to={"/accounts/" + original.id}>
          {original.firstName}
        </Link>
      ) : (
        <span>{original.firstName}</span>
      );
    },
  },
  {
    header: "Last Name",
    accessorKey: "lastName",
    Cell: ({ row: { original } }) => {
      return cmsRole && canDeleteOrModify.includes(cmsRole) ? (
        <Link className="titleLink" to={"/accounts/" + original.id}>
          {original.lastName}
        </Link>
      ) : (
        <span>{original.lastName}</span>
      );
    },
  },
  { header: "CMS Role", accessorKey: "cmsRole", enableColumnFilter: false },
  {
    header: "Email",
    accessorKey: "email",
    Cell: ({ row: { original } }) => {
      return cmsRole && canDeleteOrModify.includes(cmsRole) ? (
        <Link className="titleLink" to={"/accounts/" + original.id}>
          {original.email}
        </Link>
      ) : (
        <span>{original.email}</span>
      );
    },
  },
  {
    header: "Favorites",
    accessorKey: "favCount",
    enableColumnFilter: false,
    size: 10,
  },
  {
    header: "Update Date",
    accessorKey: "updateDate",
    enableColumnFilter: false,
    Cell: ({ row: { original } }) => (
      <span>
        {original.updateDate ? convertDateToString(original.updateDate) : "n/a"}
      </span>
    ),
  },
  {
    header: "Creation Date",
    accessorKey: "creationDate",
    enableColumnFilter: false,
    Cell: ({ row: { original } }) => (
      <span>
        {original.creationDate
          ? convertDateToString(original.creationDate)
          : "n/a"}
      </span>
    ),
  },
];

export const diets = {
  "rt:omnivore": "Omnivore",
  "rt:diet_vegetarian": "Vegetarian",
  "rt:diet_vegan": "Vegan",
  "rt:diet_low_carb": "Low Carb",
  "rt:diet_low_fat": "Low Fat",
  "rt:italian": "Mediterranean",
  "rt:diet_pescetarian": "Pescetarian",
  "rt:diet_paleo": "Paleo",
  "rt:diet_keto": "Keto",
  "rt:diet_high_protein": "High Protein",
} as const;
