import { NavLink as RemixNavLink } from "@remix-run/react";
import { getNavlinks } from "../constants/navLinks";
import { useContext } from "react";
import { UserInfoContext } from "~/utils/Providers";

const NavLinks = () => {
  const { cmsRole } = useContext(UserInfoContext);

  return (
    <section>
      {getNavlinks(cmsRole).map((group) => (
        <nav
          key={group.title}
          className="mx-[30px] mb-[20px] mt-0 flex flex-col gap-2 font-Alianza600"
        >
          <span className="flex pb-2 text-sm uppercase tracking-widest text-bgColor opacity-80">
            {group.title}
          </span>
          {group.links.map((link, i) => (
            <div
              key={i}
              className="flex cursor-pointer items-center text-menuTextColor"
            >
              <NavLink link={link.path}>{link.icon}</NavLink>
              <NavLink link={link.path} getColor>
                <Text text={link.text} />
              </NavLink>
            </div>
          ))}
        </nav>
      ))}
    </section>
  );
};

type Props = {
  link: string;
  children: React.ReactNode;
  getColor?: boolean;
};

const defaultStyle = {
  color: "hsla(47, 21%, 86%, 0.8)",
};

const NavLink = ({ link, children, getColor }: Props) => {
  const getActiveColor = ({ isActive }: { isActive: boolean }) => ({
    color: isActive ? "var(--clr-primary)" : defaultStyle.color,
  });

  return (
    <RemixNavLink
      key={link}
      to={link}
      className="hover:text-primary-100 flex items-center"
      style={getColor ? getActiveColor : defaultStyle}
    >
      {children}
    </RemixNavLink>
  );
};

type TextProps = {
  text: string;
};

const Text = ({ text }: TextProps) => (
  <div className="ml-4 text-xs uppercase tracking-widest hover:text-bgColor">
    {text}
  </div>
);

export default NavLinks;
