import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createContext } from "react";

export type UserInfo = {
  cmsRole: string | undefined;
  name: string | undefined;
};

export type Props = {
  children: React.ReactNode;
  userInfo: UserInfo;
};

export const UserInfoContext = createContext<UserInfo>({
  cmsRole: "",
  name: "",
});

const queryClient = new QueryClient();

export default function Providers({ children, userInfo }: Props) {
  return (
    <QueryClientProvider client={queryClient}>
      <UserInfoContext.Provider value={userInfo}>
        {children}
      </UserInfoContext.Provider>
    </QueryClientProvider>
  );
}
